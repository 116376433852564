.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  justify-content: left;
  pointer-events: none;
}
.Nav-bar {
  margin-right: 20px; /* Adjust the margin as needed */
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Header-content {
  display: flex;
  align-items: center;
}
.App-link {
  text-decoration: none;
  color: #ffffff;
}
.head {
  background-image: url('./images/dalphahealthim1.jpg'); /* Correct path to your image file */
  background-size: cover; /* Adjust the size as needed */
  background-position: center; /* Adjust the position as needed */
  background-repeat: no-repeat;
  height: 300px; /* Set the desired height */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For screens with a max width of 768px (adjust as needed) */
@media (max-width: 768px) {
  .Header-content {
    flex-direction: column; /* Stack items vertically on mobile */
    align-items: flex-start; /* Align items to the left on mobile */
  }

  .Nav-bar {
    margin: 10px 0; /* Adjust the margin for mobile */
  }
}
