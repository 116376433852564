.container{
    background-image: url('/src/images/about_header.jpg');
      background-size: cover; 
      align-items: center;
      justify-content: center;
      background-position: center center; /* Center the image horizontally and vertically */
    background-repeat: no-repeat; 
    height: 45vh;
   }
   .custom-horizontal-line {
    margin-top: 50px;
    
  }
  .centered-text {
    margin-top: -10px;
    color: #fff;
    text-align: center;
  }
  .corevalues{
    display: grid;
      grid-template-columns: repeat(2, 1fr); /* Creates a 2x2 grid with equal column widths */
  gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: auto;
  }
  .valuesTextbg{
background-color: #f6f7f8;
align-items: center;
  }
  .corevaluestext{
    font-family: Rubik;
    line-height: 30px;
    font-weight: 400;
    font-style: normal;
    background-color: #f6f7f8;
align-items: left;
    text-align: left;
    align-items: center;
    color: #0f192d;
    font-size: 15px;
    opacity: 1;
    visibility: visible;
    padding: 70px;
    
  }
  .corevaluesimg{
    width: 100%;
  }
 
  .aboutteam {
    margin-top: 50px;
    display: grid;
      grid-template-columns: repeat(4, 1fr); /* Creates a 2x2 grid with equal column widths */
  gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: auto;
  }
  .aboutteam a {
    text-decoration: none;
    display: flex;
    padding-top: 10px;
    color: #000;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .team > div {
    flex: 0 0 calc(25% - 20px); /* Adjust the percentage as needed */
    text-align: center;
    margin: 0 10px;
  }

  .teammembers {
    max-width: 100%;
    height: auto;
  }

  p {
    margin: 10px 0;
  }
  @media (max-width: 768px) {
    .aboutteam {
     
      display: grid;
        grid-template-columns: repeat(2, 1fr); /* Creates a 2x2 grid with equal column widths */
    gap: 10px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      overflow: auto;
    }
  
    .team-member {
      flex: 0 0 calc(25% - 10px); /* Set the desired width for larger screens */
      max-width: calc(25% - 10px); /* Set the desired width for larger screens */
    }
    .values{
        display: grid;
        grid-template-rows: 1fr 1fr; /* Two rows */
        grid-template-columns: 1fr; /* Single column for vertical alignment */
        gap: 10px;
        padding: 50px;
    }
    .corevalues{
      display: grid;
        grid-template-columns: repeat(1, 1fr); /* Creates a 2x2 grid with equal column widths */
    gap: 10px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      overflow: auto;
    }
    .valuesTextbg{
        background-color: #f6f7f8;
        text-align: center;
        margin-right: 50px;
        margin-left: -80px;
          }
          .valuesText{
            font-family: Rubik;
            line-height: 30px;
            font-weight: 400;
            font-style: normal;
            text-align: center;
            align-items: center;
            color: #0f192d;
            font-size: 18px;
            opacity: 1;
            visibility: visible;
          }
  }
