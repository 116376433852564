.navbar {
  background-color: #000;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}
.seconddivimg {
  padding: 20px;
  width: 50px;
  transition: width 1s ease;
  flex: 1; /* Share available space equally with the text-div */
}
.navbar.scrolling {
  background-color: transparent; /* Change the background color to black */
  transition: background-color 5s ease; /* Add a smooth transition effect */
}
.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  position: relative;
}
.small-logo {
  width: 70px; /* Adjust the width to make the image smaller */
  height: 70px;
  padding-left: 20px;
 /* Adjust the height to make the image smaller */
}
.nav-logo {
 
  color: #f5b921;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-items: center;
}

.nav-logo .icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-left: 16px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 5rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.05rem 0.05rem;
  height: 100%;
  font-size: 13px;
  font-weight: 500;
  
  text-transform: uppercase;
  font-style: normal;
  border-bottom: 3px solid transparent;
}
/* .fa-code {
  margin-left: 1rem;
} */

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.1s ease;
}

.nav-item:hover:after {
  width: 100%;
  color: black;
  background: #337ab7;
}

.nav-item .active {
  color: transparent;

}

.nav-icon {
  display: none;
  color: #838383;
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #000;
    position: absolute;
    top: 40px;
    left: -110%;
    text-align: left;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #000;
    border: none;
  }
  .nav-links {
    color: #000;
    padding: 1.5rem;
    width: 100%;
    height: 5%;
    display: table;
  }

  .nav-logo .icon {
    width: 5%;
    height: 5%;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #838383;
  }
}
