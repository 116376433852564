.contactcontainer{
    background-image: url('/src/images/contact.jpg');
      background-size: cover; 
      align-items: center;
      justify-content: center;
      background-position: center center; /* Center the image horizontally and vertically */
    background-repeat: no-repeat; 
    height: 45vh;
   }
   .body{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates a 2x2 grid with equal column widths */
    padding: 30px;
    gap: 10;
   }
   .inputbody{
    display: flex;
   padding-top: 10px;
   }
   .input {
    width: 50%;
    border: none; /* Remove the border */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Add shadow to the background */
    height: 40px;
}
   .mailinput{
    width: 100%;
    border: none; /* Remove the border */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); 
    height: 40px;
   }
   .form{
    border: 2px solid #000; /* 2px solid black border */
    padding: 20px; 
    padding: 20px;
    /* gap: 0;
    grid-template-columns: repeat(2, 1fr); Creates a 2x2 grid with equal column widths */
   }
   .body{
    display: grid;
    gap: 0;
    grid-template-columns: repeat(2, 1fr);
   }
   .contactdescription{
    margin-top: -20px;
    display: flex;
    padding: 0px 0px;
   }
   .contactdescription p{
    font-size: 13px;
   }
   .contact-centered-text {
    margin-top: 0px;
    color: #000;
    border: none; /* Remove the border */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);  /* 2px solid black border */
    padding: 20px; 
    text-align: center;
  }
   .form > * {
    margin: 0;
    padding: 0;
}
.button{
  background-color: #3367d6;
  color: white;
  border-radius: 30px ;
  margin: 30px;
  border: 2px solid #3367d6;
  width: 50%;
  height: 50%;

  padding: 10px 10px;
}
.button:hover{
  width: 50%;
  color: white;
  background: #000;
}
   .map-iframe {
    width: 100%; /* Set the width to 100% or your desired value */
    height: 346px; /* Set the height as needed */
    border: 0;
  }
  .address{
    display: flex;
    grid-template-rows: 2fr 1fr; /* Two rows */
    grid-template-columns: 1fr; /* Single column for vertical alignment */
   
  }
  .formboody{
    justify-content: center;
  }
  .descriptionbody{
    margin-top: -40px;
   }

  @media (max-width: 768px) {
    .body {
        display: grid;
        grid-template-rows: 1fr 1fr; /* Two rows */
        grid-template-columns: 1fr; /* Single column for vertical alignment */
        gap: 10px;
      
      }
      .why{
        background-color:#f6f7f8;
        background-size: cover; 
        
      height: 140vh;
    }
    .whybody{
        padding: 10px;
        margin-right: 0px;
    }
    .navigate{
        display: grid;
        grid-template-rows: 2fr 2fr; /* Two rows */
        grid-template-columns: 1fr; /* Single column for vertical alignment */
        gap: 10px;
        
    }
    .form{
      display: grid;
      /* gap: 0;
      grid-template-columns: repeat(2, 1fr); Creates a 2x2 grid with equal column widths */
     }
     .contactdescription{
      display: grid;
        grid-template-rows: 1fr ; /* Two rows */
        grid-template-columns: 1fr;
     }
     .contactdescription p{
      font-size: 13px;
      margin-top: 0px;
     }
     .descriptionbody{
      margin-top: -40px;
     }
     .inputbody{
      display: flex;
      flex-direction: column;
     padding-top: 10px;
     align-items: center;
     }
     .input {
      width: 100%;
      border: none; /* Remove the border */
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Add shadow to the background */
      height: 40px;
      margin-bottom: 20px;
  }
}