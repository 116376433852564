.maindiv {
  /* background-image: url('/src/images/dalphahealthim1.jpg'); */
    background-size: cover; 
    justify-content: center;
    background-position: center center; /* Center the image horizontally and vertically */
  background-repeat: no-repeat; 
    align-items: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 10;
    height: 100vh;/* Adjust as needed */
    /* Other CSS properties for positioning, size, etc. */
  }
 .homecontainer{
  background-image: url('/src/images/dalphahealthim1.jpg');
    background-size: cover; 
    justify-content: center;
    background-position: center center; /* Center the image horizontally and vertically */
  background-repeat: no-repeat; 
 }
  
  .centered-content {
    text-align: center;
  }
  .second-centered-content {
    text-align: center;
    padding: 60px;
  }
  .second-centered-content h1{
    padding-top: 50px; /* Add padding to the top */
    padding-left: 20px; /* Add padding to the left */
    padding-right: 20px; 
    font-size: 38px;
    margin-top: 0px !important;
    margin-bottom: 17px !important;
    color: #ffffff;
    line-height: 28px;
    text-align: center;
  }
  .second-centered-content p{
   /* Add padding to the top */
    padding-left: 50px; /* Add padding to the left */
    padding-right: 30px; 
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    font-style: normal;
  }
  .deo {
    font-size:60px ;
    font-weight: 500;
    
    color: #ffffff;
    padding: 0px 180px;
    line-height: 60px;
    margin-top: 0px !important;
    margin-bottom: 10px;
    letter-spacing: -1px;
    font-family: Poppins;
    font-style: normal;
    word-wrap: break-word;
    opacity: 1;
    padding: 0;
    transition: opacity 0.24s ease-in-out;
    /* text-align: center; */
    
  }
  .navigate{
    font-size: 20px;
    color: #ffffff;
    line-height: 31px;
    text-align: center;
    margin-top: 0px !important;
    margin-bottom: 30px
  }
  .discover-button {
    background-color: #3367d6; /* Change the background color */
    border: none;
    border-radius: 20px; /* Adjust the border radius to your liking */
    color: #ffffff;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .discover-button a {
    text-decoration: none;
    display: flex;
  
    color: #f0f0f0;
    align-items: center;
    justify-content: center;
  }
  
  .discover-button:hover {
    background-color: #000000; /* Change the background color on hover */
  }
  .seconddiv{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 10;
    justify-content: space-between;
  }
 
  .text-div {
    padding: 5px;
    padding-left: 30px;
    transition: width 5s ease;
    flex: 1; /* Share available space equally with the image-div */
  }
  
  .text-div-second p{
    font-family: Rubix;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: #0f192d;
    font-size: 18px;
    
    /* Share available space equally with the image-div */
  }
  .image-div {
    
    transition: width 5s ease;
    display: grid;
   
    grid-template-columns: repeat(1, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 10;
    flex: 1; /* Share available space equally with the image-div */
  }
  .text-div p{
    font-family: Rubix;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: #0f192d;

    font-size: 18px;
    
    /* Share available space equally with the image-div */
  }

  .automation{
    font-family: Rubix;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: #0f192d;
    font-size: 12px;
    padding: 35px;
    margin: 13px;
    border: 1px solid #f0f0f0;
    background-color: #ffff;
  }
  .commercialization{
    font-family: Rubix;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: #0f192d;
    font-size: 12px;
    padding: 35px;
    margin: 13px;
    background-color: #f0f0f0;
  }
  .seconddivimg {
    padding: 20px;
    width: 100%;
    transition: width 5s ease;
    flex: 1; /* Share available space equally with the text-div */
  }
  .thirddivimg{
  width: 90%;

    transition: width 5s ease;
    flex: 1;
  }
  .thirddiv{
    background-image: url('/src/images/dalphahealthimg3.jpg');
    background-size: cover; /* Fit the image to cover the entire div */
  background-position: center center; /* Center the image horizontally and vertically */
  background-repeat: no-repeat; /* Prevent image tiling */
  position: relative;
  overflow: hidden;
  
}
.slide-from-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 1s ease-in-out;
}

/* Add animation for when the element is in view */
.thirddiv.in-view .slide-from-bottom-left {
  transform: translateX(0);
}
  .fourthdiv{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 10;

    
  }
  .fifthdiv{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 10;
    margin-bottom: 100px;
  
  }
  .sixthdiv{
    background-image: url('/src/images/dalphahealthimg5.png');
    background-size: cover; /* Fit the image to cover the entire div */
  background-position: center center; /* Center the image horizontally and vertically */
  background-repeat: no-repeat; /* Prevent image tiling */
  position: relative;
  overflow: hidden;
  }
  .sixthdivimage{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 0;
    padding: 30px;
  }
  .sixthdivtext{
    text-align: center;
    color: #fff;
  }
  .seventhdiv{
    padding: 30px;
  }
  .map-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 0;
  }
  .map-description{
    padding-left: 30px;
  }
  .imageborder{
    border: 0.5px solid #f0f0f0;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    /* Additional styling for the parent div, if needed */
    /* For example, you can set a fixed width and height for the parent div */
   
  }
  .imagerowimage{
    padding: 30px;
   width: 85%;
  }
  .slide-left {
    animation: slideLeft 1s forwards; /* Apply slideLeft animation */
  }
  
  .slide-right {
    animation: slideRight 1s forwards; /* Apply slideRight animation */
  }
  .slide-up {
    animation: slideUp 1s forwards; /* Apply slideLeft animation */
  }
 
  @keyframes slideLeft {
    from {
      transform: translateX(-100%); /* Move from the left */
    }
    to {
      transform: translateX(0); /* Move to the original position */
    }
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(100%); /* Move from the right */
    }
    to {
      transform: translateX(0); /* Move to the original position */
    }
  }
  @keyframes slideUp {
    from {
      transform: translateY(100%); /* Move from the bottom */
    }
    to {
      transform: translateY(0); /* Move to the original position */
    }
  }
  
  
  @media screen and (max-width: 960px) {
    .maindiv {
      /* background-image: url('/src/images/dalphahealthim1.jpg'); */
        background-size: cover; 
        justify-content: center;
        background-position: center center; /* Center the image horizontally and vertically */
      background-repeat: no-repeat; 
        align-items: center;
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Creates a 1x4 grid with four equal columns */
        gap: 10;
        height: 100vh;/* Adjust as needed */
        /* Other CSS properties for positioning, size, etc. */
      }
    .seconddiv{
      display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 10;
      justify-content: space-between;
    }
    .seconddivimg {
      padding: 20px;
      width: 100%;
      transition: width 5s ease;
      flex: 1; /* Share available space equally with the text-div */
    }
    .second-centered-content {
      text-align: center;
      padding: 0px;
    }
    .fourthdiv{
      display: block;
    }
    
  .text-div, .image-div {
   

    transition: width 5s ease;
    align-items: center;
    justify-content: center;
    justify-items: center;
    flex: 1; /* Share available space equally with the image-div */
  }
  .sixthdivimage{
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 0;
    padding: 30px;
  }
  .fifthdiv{
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 10;
  
  }
  .map-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates a 1x4 grid with four equal columns */
    gap: 0;
  }
  .map-description{
    padding-left: 10px;
    padding-top: 30px;
  }
  .seventhdiv{
    padding: 0px;
  }
  .homecontainer{
    background-image: url('/src/images/dalphahealthim1.jpg');
      background-size: cover; 
      justify-content: center;
      background-position: center center; /* Center the image horizontally and vertically */
    background-repeat: no-repeat; 
   }
   .text-div-second{
    padding: 20px;
   }
  }