.servicecontainer{
    background-image: url('/src/images/service_header.jpg');
      background-size: cover; 
      align-items: center;
      justify-content: center;
      background-position: center center; /* Center the image horizontally and vertically */
    background-repeat: no-repeat; 
    height: 45vh;
   }
   .custom-horizontal-line{
    width: 1px;
   }
   .service-centered-text{
    margin: 30px;
    color: #000;
    text-align: center;
   }
.ranges{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates a 2x2 grid with equal column widths */
  gap: 10px;
  padding: 50px;
}
.rangesimg{
    width: 400px;
}
.rangestext{
    font-family: Rubik;
    line-height: 30px;
    font-weight: 400;
    font-style: normal;
    padding: 10px;
    color: #0f192d;
    font-size: 15px;
    opacity: 1;
    visibility: visible;
}
.servicewhy{
  background-image: url('https://dalphahealth.com/wp-content/uploads/2018/06/service-simple-image04.png?id=6959');
    background-size: cover; 
    background-position: center center; /* Center the image horizontally and vertically */
    background-repeat: no-repeat; 
    justify-content: center;
    align-items: center;
}


.whybody{
    padding: 70px;
    margin-right: 300px;
}
.why p {
    font-family: Rubik;
    line-height: 28px;
    font-weight: 400;
    font-style: normal;
    color: #0f192d;
    font-size: 18px;
    opacity: 1;
    visibility: visible;
}
.serviceitems{
  display: flex;
  align-items: center;
}
.servicetext{
  flex: 1;
}
.serviceitems img{
  flex: 0 0 auto; /* Don't allow the image to grow or shrink */
  margin-right: 20px; 

}
.grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates a 2x2 grid with equal column widths */
gap: 20px;
}
.griditems{
    display: flex;
}
.griditems p {
    font-family: Rubik;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    color: #0f192d;
    font-size: 20px;
    opacity: 1;
    visibility: visible; 
}
.griditems h4 {
  font-family: Rubik;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  color: #0f192d;
  font-size: 20px;
  opacity: 1;
  visibility: visible; 
}
.servicenavigate{
    display: flex;
    background-color: rgb(40,116,252);
    padding: 40px;
    align-items: center;
  
  justify-content: space-between;

    
}
.handimg{
  width: 20%;
  height: 30%;
}
.femaleimg{
  width: 100%;
  height: 100%;
}
.shareimg{
  width: 25%;
  height: 40%;
}
.markimg{
  width: 30%;
}
.servicenavigate h3 {
    flex: 1;
    color: #f0f0f0;

  }
.servicebutton{
    background-color: rgb(40,116,252);
    border: 1px solid #f0f0f0;
    border-radius: 20px;
    color: #f0f0f0;
    text-align: center;
    width: 150px;
    height: 50px;
}
.servicebutton a {
    text-decoration: none;
    display: flex;
  
    color: #f0f0f0;
    align-items: center;
    justify-content: center;
  }
  
@media (max-width: 768px) {
    .ranges {
        display: grid;
        grid-template-rows: 1fr 1fr; /* Two rows */
        grid-template-columns: 1fr; /* Single column for vertical alignment */
     
      }
     
    
    .servicenavigate{
        display: grid;
        grid-template-rows: 1fr ; /* Two rows */
        grid-template-columns: 1fr; /* Single column for vertical alignment */
        gap: 10px;
        
    }
    .rangestext{
        font-family: Rubik;
        line-height: 30px;
        font-weight: 400;
        font-style: normal;
        margin-right: 25px;
        color: #0f192d;
        font-size: 15px;
        opacity: 1;
        visibility: visible;
    }
    .serviceitems{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  .handimg{
    width: 50%;
  }
  .shareimg{
    width: 40%;
    height: 30%;
  }
}