.footer{
    margin-top: 50px;
    background-color: #000;
    color: #fff;
    padding: 10px;
}
.text-right {
    text-align: right;
    font-size: 13px;
    padding-right: 30px;
  }
  
  @media screen and (max-width: 960px) {
    .text-right {
        text-align: center;
        font-size: 13px;

      }
  }