.careercontainer{
    background-color: #f6f7f8;
      background-size: cover; 
      align-items: center;
      justify-content: center;
      background-position: center center; /* Center the image horizontally and vertically */
    background-repeat: no-repeat; 
    height: 45vh;
   }
   .message{
    display: flex;
    padding: 30px;
   }
   .partner{
    background-color: rgb(113,206,126);
    flex: 70%; 
    position: relative;/* Set the "partner" div to take up 60% of the width */
    height: 80px;
    /* &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 100%;
      border-right: 0px solid transparent; 
      border-left: 20px solid; 
      transform: skewX(-30deg); 
    } */
   }
   .mail{
    background-color: rgb(25, 57, 170);
    position: relative;
    flex: 30%; /* Set the "mail" div to take up 40% of the width */
    height: 80px;
    
    /* &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      border-left: 10px solid transparent; 
      border-right: 20px solid rgb(25, 57, 170);
      transform: skewX(-40deg); 
    } */
   }
   .mail p{
    color: #fff;
    font-size: 10px;
    margin-top: 20px;
    text-align: center;
   }
   .mail h3{
    color: #fff;
    text-align: center;
    margin-top: -10px;
    font-size: 14px;
   }
   .partner h3{
    color: #fff;
   padding-left: 20px;
   }
   .peopleimg{
    background-image: url('/src/images/careerimg.jpg');
     margin-top: 40px;
     margin-bottom: 40px;
      background-size: cover; 
      align-items: center;
      background-size: cover; 
      justify-content: center;
      background-position: center center; /* Center the image horizontally and vertically */
    background-repeat: no-repeat; 
    height: 150vh;
   }
   @media screen and (max-width: 960px) {
    .message{
      display: block;
      padding-left: 10px;
      padding-right: 10px;
     }
     .peopleimg{
      margin-top: 40px;
      margin-bottom: 40px;
       background-size: cover; 
       align-items: center;
       background-size: cover; 
       justify-content: center;
       background-position: center center; /* Center the image horizontally and vertically */
     background-repeat: no-repeat; 
     height: 150vh;
    }
    .mail{
      background-color: rgb(25, 57, 170);
      position: relative;
      flex: 30%; /* Set the "mail" div to take up 40% of the width */
      height: 80px;
      margin-top: -20px;
      
     }
     
   }